import { ServiceSurvey } from 'data/enums/service-survey'
import { TripScheduleService } from 'data/types/bookings/trip_schedule'
import { getAirportTransferServiceDetails } from 'data/helpers/survey/tripScheduleSurveys/airport-transfer'
import { getBoatCharterServiceDetails } from 'data/helpers/survey/tripScheduleSurveys/boat-charter'
import { getCarRentalServiceDetails } from 'data/helpers/survey/tripScheduleSurveys/car-rental'
import { getAtHomeCookingClassDetails } from 'data/helpers/survey/tripScheduleSurveys/at-home-cooking-class'
import { getPrivateChefServiceDetails } from 'data/helpers/survey/tripScheduleSurveys/private-chef'
import { parseTime } from 'data/helpers/time'
import { formatTimeFromTime } from 'data/helpers/dates'
import { joinBy } from '@ama-selections/ui'

const getServiceDetails = (service?: TripScheduleService) => {
  if (!service) {
    return undefined
  }

  switch (service.group?.service?.survey_slug) {
    case ServiceSurvey.PRIVATE_CHEF:
      return getPrivateChefServiceDetails(service)
    case ServiceSurvey.AIRPORT_TRANSFER:
      return getAirportTransferServiceDetails(service)
    case ServiceSurvey.BOAT_CHARTER:
    case ServiceSurvey.SAILBOAT_CHARTER:
      return getBoatCharterServiceDetails(service)
    case ServiceSurvey.CAR_RENTAL:
      return getCarRentalServiceDetails(service)
    case ServiceSurvey.AT_HOME_COOKING_CLASS:
    case ServiceSurvey.PIZZA_MAKING_CLASS:
      return getAtHomeCookingClassDetails(service)
    default:
      return service.service_details ?? service.group?.service_details
  }
}

const getStartTime = (startTime?: string, formatFromTime = false) => {
  const formatFunction = formatFromTime ? formatTimeFromTime : parseTime
  return startTime ? formatFunction(startTime, true) : 'TBC'
}

const getFormattedTime = (
  startTime?: string | null,
  endTime?: string | null,
) => {
  if (!startTime && !endTime) {
    return 'TBC'
  }

  return joinBy(
    [
      formatTimeFromTime(startTime),
      formatTimeFromTime(endTime),
    ],
    ' - ',
  )
}

export {
  getServiceDetails,
  getStartTime,
  getFormattedTime,
}
