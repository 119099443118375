import { TripScheduleService } from 'data/types/bookings/trip_schedule'
import { SurveyAtHomeCookingClassInfo } from 'gql/graphql'
import { getStartTime } from 'data/helpers/survey/tripSchedule'
import { joinBy, pluralise } from '@ama-selections/ui'

export const getAtHomeCookingClassDetails = (service: TripScheduleService) => {
  const serviceDetails = service.service_details ?? service.group?.service_details

  if (serviceDetails?.includes('$generate_details')) {
    const survey = service.survey as SurveyAtHomeCookingClassInfo

    const line1 = `(${getStartTime(service.start_time, true)})`

    const adults = pluralise(survey?.adults, 'adult', 's', '', true)
    const children = survey?.children
      ? pluralise(survey?.children, 'child', 'ren', '', true)
      : null

    const line2 = joinBy(
      [
        adults,
        children,
      ],
      ' + ',
    )

    const line3 = survey?.cuisine

    const line4 = survey?.allergies

    return serviceDetails.replaceAll(
      '$generate_details',
      joinBy(
        [
          line1,
          line2,
          line3,
          line4,
        ],
        '\n',
      ),
    )
  }

  return serviceDetails
}
