import { useRouter } from 'next/router'

export const useRouterWithQuery = () => {
  const router = useRouter()

  const updateQueryParam = (key: string, value: string) => {
    const query = { ...router.query }

    if (value) {
      query[key] = value
    } else {
      delete query[key]
    }

    router.push(
      {
        query: query,
      },
      undefined,
      { shallow: true },
    )
  }

  const removeQueryParam = (key: string) => {
    const query = { ...router.query }
    delete query[key]

    router.push(
      {
        query: query,
      },
      undefined,
      { shallow: true },
    )
  }

  return {
    router,
    updateQueryParam,
    removeQueryParam,
  }
}
