enum ServiceSurvey {
  PRIVATE_CHEF = 'private-chef',
  PRE_STOCKING = 'pre-stocking',
  AIRPORT_TRANSFER = 'airport-transfer',
  BOAT_CHARTER = 'boat-charter',
  SAILBOAT_CHARTER = 'sailboat-charter',
  CAR_RENTAL = 'car-rental',
  AT_HOME_COOKING_CLASS = 'at-home-cooking-class',
  PIZZA_MAKING_CLASS = 'pizza-making-class',
}

export { ServiceSurvey }
